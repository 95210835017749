import React, { ReactElement } from "react";
import {
	DataObserveKey,
	FocusableComponent,
	IconElementProps,
	VisualComponent,
	f2u,
	cn,
	SrOnly,
	Link,
} from "@siteimprove/fancylib";
import { ActionMenu, ActionMenuProps } from "../../actions-and-controls/action-menu/action-menu";
import { useLabTranslations } from "../../../translations/translations";
import * as scss from "./horizontal-navigation.scss";

export type HorizontalNavigationProps = {
	appLogo?: {
		icon: ReactElement<IconElementProps>;
		alt: string;
	} & Action;
	actions: Array<ActionMenuProps & { "data-observe-key": string }>;
	skipToContentId?: string;
	"aria-label"?: string;
} & DataObserveKey &
	VisualComponent &
	FocusableComponent;

interface ActionClick {
	"data-observe-key"?: string;
	onClick: () => void;
}

interface ActionLink {
	"data-observe-key"?: string;
	href: string;
	openNew?: boolean;
}

type Action = ActionClick | ActionLink;

export function HorizontalNavigation(props: HorizontalNavigationProps): JSX.Element {
	const { appLogo, actions, skipToContentId, className, style } = props;
	const i18nLab = useLabTranslations();

	function renderAppLogo(
		icon: ReactElement<IconElementProps>,
		alt: string,
		action: Action
	): JSX.Element {
		if ("href" in action) {
			return (
				<a
					className={scss.logo}
					data-observe-key={action["data-observe-key"]}
					href={action.href}
					title={alt}
					rel={f2u(action.openNew && "noopener")}
					target={f2u(action.openNew && "_blank")}
				>
					{icon}
				</a>
			);
		}

		if ("onClick" in action) {
			return (
				<button
					className={scss.logo}
					data-observe-key={action["data-observe-key"]}
					type="button"
					onClick={() => action.onClick()}
				>
					{icon}
				</button>
			);
		}

		return <></>;
	}

	function renderActionMenus(): React.ReactNode {
		return actions.map((item) => (
			<ActionMenu
				{...item}
				key={item["data-observe-key"]}
				buttonProps={{
					...item.buttonProps,
					size: "large",
					variant: "borderless",
				}}
				hideChevron
				className={scss.actionMenu}
				tooltipPlacement={item.tooltip ? "bottom" : undefined}
			/>
		));
	}

	return (
		<header
			data-component="HorizontalNavigation"
			data-observe-key={props["data-observe-key"]}
			role="banner"
			aria-label={props["aria-label"]}
			className={cn(scss.horizontalNavigation, className)}
			style={style}
		>
			{skipToContentId && (
				<SrOnly>
					<Link href={`#${skipToContentId}`} className={scss.skipToContent}>
						{i18nLab.skipToContent}
					</Link>
				</SrOnly>
			)}
			{appLogo && renderAppLogo(appLogo.icon, appLogo.alt, appLogo)}
			{actions && renderActionMenus()}
		</header>
	);
}
